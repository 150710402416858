<template>
  <div class="w-full flex flex-col gap-5">
    <template v-if="post">
      <NuxtImg
        v-if="post.image"
        class="w-full max-md:max-h-[60vw] h-[250px] md:h-[325px] lg:h-[500px] object-cover rounded-lg"
        :src="post.image"
        :alt="post.imageAlt"
        sizes="320 sm:768"
        densities="x1"
        provider="custom"
        format="webp"
        fit="crop"
      />
      <div class="flex items-center justify-between">
        <h1 class="text-xl md:text-2xl font-bold [text-wrap:balance]">
          {{ post.title }}
        </h1>
        <AppButton
          class="variant-invisible icon-button h-[36px] w-[36px] min-w-[36px] rounded-full web-share-supported"
          :icon="mdiShareVariant"
          :title="t('property.share')"
          :aria-label="t('property.share')"
          @click="shareArticle"
        />
        <AppButton
          class="variant-invisible icon-button h-[36px] w-[36px] min-w-[36px] rounded-full web-share-not-supported"
          :icon="mdiShareVariant"
          :title="t('property.copyShareLink')"
          :aria-label="t('property.copyShareLink')"
          @click="copyShareLink"
        />
      </div>
      <div class="flex gap-x-4 gap-y-1 md:items-center mb-2 max-md:flex-col">
        <!-- date -->
        <div class="flex items-center gap-2">
          <Icon class="w-6 min-w-6 h-6 text-neutral" :path="mdiCalendar" />
          <div class="text-sm text-neutral-light mr-2">
            {{ formatDate(post.date) }}
          </div>
        </div>
        <!-- categories -->
        <div v-if="post.categories?.length" class="flex items-center gap-2">
          <Icon class="w-6 min-w-6 h-6 text-neutral" :path="mdiTagMultiple" />
          <ul class="text-neutral-light text-sm comma-separeted-list">
            <li v-for="category in post.categories" :key="category.name">
              <NuxtLink :to="category.path">
                {{ category.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
        <!-- tags -->
        <div v-if="post.tags?.length" class="flex items-center gap-2">
          <Icon class="w-6 min-w-6 h-6 text-neutral" :path="mdiPound" />
          <ul class="text-neutral-light text-sm comma-separeted-list">
            <li v-for="tag in post.tags" :key="tag.name">
              <span>
                <!-- :to="tag.path" -->
                {{ tag.name }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="title-divider mb-2"></div>
      <div
        v-if="postContent.contentTable?.length"
        class="content-table -mt-2 -mb-1"
      >
        <p class="font-bold text-sm mb-2">
          {{ t('blog.contentTable') }}
        </p>
        <ul class="text-sm">
          <li
            v-for="item in postContent.contentTable"
            :key="item.id"
            class="content-table-item"
            :class="item.classes"
          >
            <a
              :href="'#' + item.id"
              class="cursor-pointer text-neutral-light hover:text-neutral transition-colors duration-200 ease-in-out"
              v-html="item.text"
            ></a>
          </li>
        </ul>
      </div>
      <div class="html-content post-content" v-html="postContent.content"></div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  mdiCalendar,
  mdiPound,
  mdiShareVariant,
  mdiTagMultiple
} from '@mdi/js';
import { useShare } from '@vueuse/core';
import type { PropType } from 'vue';

import type { Post } from '@/types/common';

import '@/assets/css/content.scss';

const props = defineProps({
  post: {
    type: Object as PropType<Post | null>
  }
});

const { t } = useI18n();

const { language } = useLanguage();

const { share } = useShare();

function formatDate(date: string) {
  return new Intl.DateTimeFormat(language.value, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }).format(new Date(date));
}

function shareArticle() {
  share({
    title: props.post?.title || '',
    text: t('blog.shareText'),
    url: window.location.href
  });
}

const { addToast } = useToasts();

const copyShareLink = () => {
  navigator.clipboard.writeText(
    `${t('blog.shareText')} ${window.location.href}`
  );
  addToast({
    title: t('blog.shareLinkCopied'),
    type: 'success'
  });
};

const postContent = computed(() => {
  let content = props.post?.content || '';

  const headings = content.match(/<h[2-6][^>]*>.*?<\/h[2-6]>/g) || [];

  const contentTable: { id: string; text: string; classes: string }[] = [];

  headings.forEach(heading => {
    const openTag = heading.match(/<[^>]*>/g)?.[0] || '';

    const level = openTag.match(/h(\d)/)?.[1] || '';

    let id = openTag.match(/id="([^"]*)"/)?.[1] || '';

    const text = heading.replace(/<[^>]*>/g, '');

    if (!id) {
      id = text
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/^-|-$/g, '');

      const headingTag = openTag.match(/<(\w+)/)?.[1] || '';
      const headingTagWithId = `<${headingTag} id="${id}">`;
      content = content.replace(openTag, headingTagWithId);
    }

    const classes = `heading-${level}`;

    contentTable.push({ id, text, classes });
  });

  return { content, contentTable };
});
</script>

<style scoped lang="scss">
.comma-separeted-list {
  li {
    display: inline-block;
    &:not(:last-child) {
      &::after {
        content: ',';
        margin-right: 0.5em;
      }
    }
  }
}

.content-table-item {
  &.heading-2 {
    @apply ml-4;
  }
  &.heading-3 {
    @apply ml-8;
  }
  &.heading-4 {
    @apply ml-12;
  }
  &.heading-5 {
    @apply ml-16;
  }
  &.heading-6 {
    @apply ml-20;
  }
}
</style>
